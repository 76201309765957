import React from 'react'
import { Link } from 'react-router-dom'

function About() {
  return (
    <>
       <section className="about-area about-p pt-120 pb-120 p-relative fix" style={{ background: "#eff7ff" }} >
            <div className="animations-02">
                <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>

            <div className="container">
                <div className="row justify-content-center align-items-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                    <img src="assets/img/features/about_img_02.png" alt="img" />
                    <div className="about-text second-about">
                        <span>
                        5 <sub>+</sub>
                        </span>
                        <p>Years of Experience</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                    <div className="about-title second-title pb-25">
                        <h5>
                        <i className="fal fa-graduation-cap" /> About Our BookMyCareer
                        </h5>
                        <h2>A Few Words About the BookMyCareer</h2>
                    </div>
                    <p className="txt-clr">
                    Book My Career of advice-giving and support provided by career counselors to their clients, to help the clients manage their journey through life, learning and work changes (career). This includes career exploration, making career choices, managing career changes, lifelong career development and dealing with other career-related issues. 
                    </p>
                    <p>
                    There is no agreed definition of career counseling worldwide, mainly due to conceptual, cultural and linguistic differences. However, the terminology of 'career counseling' typically denotes a professional intervention which is conducted either one-on-one or in a small group.

                    </p>
                    <div className="about-content2">
                        <div className="row">
                        <div className="col-md-12">
                            <ul className="green2">
                            <li>
                                <div className="abcontent">
                                <div className="ano">
                                    <span>01</span>
                                </div>{" "}
                                <div className="text">
                                    <h3>Admission for Spring Top Collage India.</h3>{" "}
                                    {/* <p>
                                    consectetur adipiscing elit sed do eiusmod tem incid
                                    idunt.
                                    </p> */}
                                </div>
                                </div>
                            </li>
                            <li>
                                <div className="abcontent">
                                <div className="ano">
                                    <span>02</span>
                                </div>{" "}
                                <div className="text">
                                    <h3>Abroad Admission Top Collage</h3>{" "}
                                    {/* <p>
                                    consectetur adipiscing elit sed do eiusmod tem incid
                                    idunt.
                                    </p> */}
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div className="slider-btn mt-20">
                        <Link to="/about" className="btn ss-btn smoth-scroll">
                        Read More <i className="fal fa-long-arrow-right" />
                        </Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
       </section>
    </>
  )
}

export default About