import React from 'react'
import { Link } from 'react-router-dom'

function Main() {
  return (
    <>
        <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#125875", backgroundImage: "url(assets/img/bg/footer-bg.png)" }} >
            <div className="footer-top pb-70">
                <div className="container">
                <div className="row justify-content-between">
                    <div className="col-xl-4 col-lg-4 col-sm-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>About Us</h2>
                        </div>
                        <div className="f-contact">
                        <p>
                        Book My Career of advice-giving and support provided by career counselors to their clients, to help the clients manage their journey through life, learning and work changes (career).
                        </p>
                        </div>
                        <div className="footer-social mt-10">
                            <a href="#">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a href="#">
                                <i className="fab fa-instagram" />
                            </a>
                            <a href="#">
                                <i className="fab fa-twitter" />
                            </a>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-sm-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>Our Links</h2>
                        </div>
                        <div className="footer-link">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about"> About</Link>
                            </li>
                            <li>
                                <Link to="/courses">Courses</Link>
                            </li>
                            <li>
                                <Link to="/contact"> Contact Us</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog </Link>
                            </li>
                            <li>
                                <Link to="/">List of Top Colleges </Link>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>Links</h2>
                        </div>
                        <div className="footer-link">
                        <ul>
                            <li>
                                <Link to="/">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/"> Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="/">Our Refund Policy</Link>
                            </li>
                            <li>
                                <Link to="/"> Required Documents</Link>
                            </li>
                            <li>
                                <Link to="/">Feedback </Link>
                            </li>
                            <li>
                                <Link to="/">Share Your Experience </Link>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-sm-6">
                    <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>Contact Us</h2>
                        </div>
                        <div className="f-contact">
                        <ul>
                            <li>
                            <i className="icon fal fa-phone" />
                            <span>
                                <Link to="tel:+919135200300">+91 9135200300</Link>
                                <br />
                                <Link to="tel:+910000000000">+91 00000000000</Link>
                            </span>
                            </li>
                            <li>
                            <i className="icon fal fa-envelope" />
                            <span>
                                <Link to="mailto:info@bookmycareer.co">info@bookmycareer.co</Link>
                                <br />
                                <Link to="mailto:support@bookmycareer.co">support@bookmycareer.co</Link>
                            </span>
                            </li>
                            <li>
                            <i className="icon fal fa-map-marker-check" />
                            <span>
                            Anand Tower 5th Floor,5-D, Exhibition Road,
                                 Near Pantaloons Showroom <br/>
                            </span>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                    <div className="copy-text">
                        <Link to="/">
                            <img src="assets/img/logo/logo.png" alt="img" />
                        </Link>
                    </div>
                    </div>
                    <div className="col-lg-4 text-center"></div>
                    <div className="col-lg-4 text-right text-xl-right">
                        Copyright © bookmycareer 2024 . All rights reserved.
                    </div>
                </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Main